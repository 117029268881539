/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Our proven areas of expertise',
  itConsultingTitle: 'Web Technology Consulting',
  AppDevelopmentTitle: 'App Development',
  SystemsEngineeringTitle: 'Systems Engineering',
  UIUXDesignTitle: 'UX Engineering & UI Design',
  DevelopmentTitle: 'Web Application & Platform Development',
  SystemIntegrationTitle: 'System Integration & Architecture',
  showMoreBtn: 'Show details',
  titleLink: 'All Services',

  itConsultingCopy: 'Digital projects are always iterative processes. Sometimes it’s necessary to step back to go forward. We support you with collaborative consulting to get the best out of it.',
  itConsultingItem1: 'Holistic analysis of your system environments',
  itConsultingItem2: 'Defining your individual needs',
  itConsultingItem3: 'Optimized processes and efficient workflows for you',

  conceptStrategyTitle: 'Software Concept & Strategy',
  conceptStrategyCopy: 'Only those who accept change can grow. We support you in the ongoing development and expansion of your projects. This is our understanding of a sustainable and long-term partnership. We follow up on new technologies and market trends to identify new areas of growth. We have years of expertise and a global network of experts. This allows us to introduce to you pioneering options for action.',
  conceptStrategyItem1: 'Continuous streamlining and optimization of digital processes',
  conceptStrategyItem2: 'Flexible adaptation of your environments to your needs',
  conceptStrategyItem3: 'Identification of new areas of growth',

  projectManagementTitle: 'Project Management',
  projectManagementCopy: 'Your project success: Reaching the defined goal in time and budget. Our project managers oversee everything and act as contact partners for all project members. Friendliness, personality, expertise, and experience. These are the pillars of a successful project.',
  projectManagementItem1: 'Focus on business values',
  projectManagementItem2: 'Expectation management with all parties involved',
  projectManagementItem3: 'Clear division of project management and technical project management roles',

  uxuiDesignTitle: 'UX Engineering & UI Design',
  uxuiDesignCopy: 'Websites need to be increasingly dynamic. They must be individual, easy to use, and mobile responsive. Our UX experts develop frontend concepts that keep pace and even stay ahead of the times.',
  uxuiDesignItem1: 'Analysis, Strategy & Conception',
  uxuiDesignItem2: 'User Interface & Interaction Design',
  uxuiDesignItem3: 'User Testing & Usability Optimization',
  uxuiDesignItem4: 'Front-End Development & UX Engineering',

  developmentTitle: 'Web Application & Platform Development',
  developmentCopy: 'Flexibility, stability, and scalability are indicators for the quality of digital systems. We add usability and value. We aim to develop solutions that fit your needs. We involve the best, experienced developers to meet our standards of quality.',
  developmentItem1: 'Conception, design, development and optimization',
  developmentItem2: 'High-performance systems to digitize your business processes',
  developmentItem3: 'State-of-the-art technology, microservice architecture and scaling via the cloud',

  systemIntegrationTitle: 'System Integration & Architecture',
  systemIntegrationCopy: 'System landscapes generally grow at their own pace. Our systems engineering team provides you with integration security.',
  systemIntegrationItem1: 'Analysis of functional models and current system architectures',
  systemIntegrationItem2: 'Provision of service-oriented architectures',
  systemIntegrationItem3: 'Delivery of stable systems and web solutions',

  appDevelopmentTitle: 'App Development',
  appDevelopmentCopy: 'Native mobile application design is one of our core competencies. We develop with React Native and other efficient frameworks. We design dedicated apps for popular operating systems like iOS and Android. We are also ready to develop for Windows and other operating systems.',
  appDevelopmentItem1: 'Developing native and cross-platform mobile apps',
  appDevelopmentItem2: 'Handling complex functionalities in mobile app user interfaces',
  appDevelopmentItem3: 'Optimizing mobile apps for best performance',

  systemsEngineeringTitle: 'Systems Engineering',
  systemsEngineeringCopy: 'Systems should support the users. No barriers combined with processes that follow the users’ operations are key. When tackling any type of systems engineering project, a ground-up approach makes all the difference. We maintain a common understanding and vision of the individual requirements.',
  systemsEngineeringItem1: 'Real-time web development and engineering',
  systemsEngineeringItem2: 'Advanced software implementation methodologies',
  systemsEngineeringItem3: 'Object-oriented programming standards',

  toolsTitle: 'Tools',
  toolsItem1: 'Developing native and cross-platform mobile apps',
  toolsItem2: 'Handling complex functionalities in mobile app user interfaces',
  toolsItem3: 'Optimizing mobile apps for best performance',

  hcmsIntegrationTitle: 'Headless CMS Integration',
  hcmsIntegrationItem1: 'Flexible use for a wide variety of frontends',
  hcmsIntegrationItem2: 'Comprehensive content management',
  hcmsIntegrationItem3: 'Generate dynamic APIs'
})
