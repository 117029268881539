import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import messages from './Services.lang'

/* eslint-disable max-len */
const imgConsulting = <StaticImage src='../../images/services/service_1.jpg' alt='' objectFit='cover' className='imageCover' />
const imgConceptStrategy = <StaticImage src='../../images/services/service_2.jpg' alt='' objectFit='cover' className='imageCover' />
const imgProjectManagement = <StaticImage src='../../images/services/service_3.jpg' alt='' objectFit='cover' className='imageCover' />
const imgDesign = <StaticImage src='../../images/services/service_4.jpg' alt='' objectFit='cover' className='imageCover' />
const imgDevelopment = <StaticImage src='../../images/services/service_5.jpg' alt='' objectFit='cover' className='imageCover' />
const imgSystemIntegration = <StaticImage src='../../images/services/service_6.jpg' alt='' objectFit='cover' className='imageCover' />
const imgAppDevelopment = <StaticImage src='../../images/services/service_7.jpg' alt='' objectFit='cover' className='imageCover' />
const imgSystemEngineering = <StaticImage src='../../images/services/service_8.jpg' alt='' objectFit='cover' className='imageCover' />
const hcmsIntegration = <StaticImage src='../../images/services/service_10.jpg' alt='' objectFit='cover' className='imageCover' />
/* eslint-enable max-len */

export const servicesList = {
  conceptStrategy: {
    title: messages.conceptStrategyTitle,
    localLink: '/services/concept-strategy/',
    img: imgConceptStrategy,
    icon: 'icon-strategy',
    info: [
      messages.conceptStrategyItem1,
      messages.conceptStrategyItem2,
      messages.conceptStrategyItem3
    ]
  },
  consulting: {
    title: messages.itConsultingTitle,
    localLink: '/services/it-consulting/',
    img: imgConsulting,
    icon: 'icon-communicate',
    info: [
      messages.itConsultingItem1,
      messages.itConsultingItem2,
      messages.itConsultingItem3
    ]
  },
  design: {
    title: messages.UIUXDesignTitle,
    localLink: '/services/ui-ux-design/',
    img: imgDesign,
    icon: 'icon-design',
    info: [
      messages.uxuiDesignItem1,
      messages.uxuiDesignItem2,
      messages.uxuiDesignItem3,
      messages.uxuiDesignItem4
    ]
  },
  development: {
    title: messages.DevelopmentTitle,
    localLink: '/services/development/',
    img: imgDevelopment,
    icon: 'icon-development-head',
    info: [
      messages.developmentItem1,
      messages.developmentItem2,
      messages.developmentItem3
    ]
  },
  systemIntegration: {
    title: messages.SystemIntegrationTitle,
    localLink: '/services/system-integration/',
    img: imgSystemIntegration,
    icon: 'icon-checklist',
    info: [
      messages.systemIntegrationItem1,
      messages.systemIntegrationItem2,
      messages.systemIntegrationItem3
    ]
  },
  systemEngineering: {
    title: messages.SystemsEngineeringTitle,
    localLink: '/services/system-engineering/',
    img: imgSystemEngineering,
    icon: 'icon-development',
    info: [
      messages.systemsEngineeringItem1,
      messages.systemsEngineeringItem2,
      messages.systemsEngineeringItem3
    ]
  },
  appDevelopment: {
    title: messages.AppDevelopmentTitle,
    localLink: '/services/app-development/',
    img: imgAppDevelopment,
    icon: 'icon-pen-and-monitor',
    info: [
      messages.appDevelopmentItem1,
      messages.appDevelopmentItem2,
      messages.appDevelopmentItem3
    ]
  },
  projectManagement: {
    title: messages.projectManagementTitle,
    localLink: '/services/project-management/',
    img: imgProjectManagement,
    icon: 'icon-management',
    info: [
      messages.projectManagementItem1,
      messages.projectManagementItem2,
      messages.projectManagementItem3
    ]
  },
  hcmsIntegration: {
    title: messages.hcmsIntegrationTitle,
    img: hcmsIntegration,
    localLink: '/services/headless-cms-integration/',
    icon: 'icon-management',
    info: [
      messages.hcmsIntegrationItem1,
      messages.hcmsIntegrationItem2,
      messages.hcmsIntegrationItem3
    ]
  }
}
