// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl, Link } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import { Container } from '../../components/basic/Grid'
import Button from '../../components/basic/Button'
import messages from './Services.lang'
import { servicesList } from './services'
import baseStyles from './Services.scss'

type Props = {
  ...StyleProps,
  services?: Array<string>
}

const Services = ({
  styles,
  services = [
    'systemIntegration',
    'conceptStrategy',
    'design',
    'development',
    'systemEngineering',
    'appDevelopment',
    'consulting',
    'hcmsIntegration'
  ]
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <Container className={styles.root} fluid>
      <div className={styles.sectionTitleWrapper}>
        <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
        <Link to="/services/">
          <Button styles={styles.btn} tag="span" color="link" iconRight="icon-right">
            {formatMessage(messages.titleLink)}
          </Button>
        </Link>
      </div>
      <section className={styles.teaserSection}>
        {services.map((item, i) => (
          <Link
            key={'service' + i}
            to={servicesList[item].localLink}
            className={styles.link}
            activeClassName={styles['is-active']}
            partiallyActive
          >
            <span className={classNames(styles.teaserIcon, servicesList[item].icon)} />
            <h3 className={styles.teaserTitle}>{formatMessage(servicesList[item].title)}</h3>
          </Link>
        ))}
      </section>
    </Container>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Services)
